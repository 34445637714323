.DropdownItemsTextListWithControls {
	&__wrapper {
		position: relative;
	}
	&__popover {
		position: absolute;
		left: 0;
		top: 100%;
		z-index: 5;
		width: 250px;
	}
	&__inner {
		border: 1px solid #ccc;
		box-shadow: 0px 2px 10px 5px rgba(161, 161, 161, 0.2);
		padding: 5px 10px;
		background-color: #fff;
	}
	&__list {
		height: 300px;
		overflow-y: auto;
	}
}
